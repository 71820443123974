<div class="login" *ngIf="isLoading">
	<div style="width: 80%; margin: 100px auto;">
		Please wait...
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	</div>
</div>
<mat-sidenav-container class="sidenav-container" *ngIf="!fullPageTemplate && !isLoading">
    <mat-sidenav #drawer class="sidenav" fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
      <mat-toolbar class="side-logo-img" [style]="!isPyrocast ? 'height: '+(isTune2 ? '120':'90')+'px' : ''">
        <a routerLink="/">
          <img [src]="'assets/img/' + logo" [style]="!isPyrocast ? 'width: '+(isTune2 ? '90':'120')+'px' : ''" />
        </a>
      </mat-toolbar>
      <mat-nav-list class="main-menu">
				<!-- <div style="text-align: center; margin-bottom: 15px;" *ngIf="token.linkedUsers">
					<button mat-button [matMenuTriggerFor]="menu">Switch User</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item *ngFor="let linkedUser of token.linkedUsers" [hidden]="linkedUser==token.email" (click)="linkedUserLogin(linkedUser)">{{ linkedUser }}</button>
					</mat-menu>
        </div> -->
        <div style="text-align: center; margin-bottom: 15px;" *ngIf="token.collaborators && token.collaborators.length > 0">
					<button mat-stroked-button [matMenuTriggerFor]="menu">Switch User</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item *ngFor="let collaborator of token.collaborators" [hidden]="collaborator == token.email" (click)="collaboratorLogin(collaborator)">{{ collaborator }}</button>
					</mat-menu>
				</div>
				<a mat-list-item routerLink="home" (click)="closeDrawer()"><mat-icon>grid_view</mat-icon>My Shows</a>
				<a mat-list-item routerLink="brands" (click)="closeDrawer()" [class]="!env.showBrands ? 'hidden' : ''"><mat-icon>format_paint</mat-icon>My Brands</a>
        <!-- <a mat-list-item *ngIf="isAdmin" routerLink="linked-users" (click)="closeDrawer()"><mat-icon>format_paint</mat-icon>Linked User</a> -->
        <a mat-list-item routerLink="collaborator" (click)="closeDrawer()"><mat-icon>assessment</mat-icon>Collaborators</a>
        <a mat-list-item *ngIf="isAdmin" routerLink="invalidation-logs" (click)="closeDrawer()"><mat-icon>assessment</mat-icon>Invalidation Logs</a>
        <a mat-list-item *ngIf="isAdmin" routerLink="user-reporting" (click)="closeDrawer()"><mat-icon>assessment</mat-icon>Users Reporting</a>
        <a mat-list-item routerLink="likes" (click)="closeDrawer()"><mat-icon>assessment</mat-icon>My Likes</a>
        <a mat-list-item *ngIf="isTune2" (click)="apiKey()"><mat-icon>assessment</mat-icon>API Key</a>
        <!-- <a mat-list-item *ngIf="isPyrocast" routerLink="billing"><mat-icon>assessment</mat-icon>Billing</a> -->
        <a mat-list-item *ngIf="isAdmin" (click)="closeDrawer()" routerLink="pro-reporting"><mat-icon>format_paint</mat-icon>PRO Reporting</a>
        <a mat-list-item *ngIf="isAdmin" (click)="closeDrawer()" routerLink="notifications"><mat-icon>format_paint</mat-icon>Notifications</a>
				<a mat-list-item (click)="settings()"><mat-icon>settings</mat-icon>Settings</a>
        <a mat-list-item routerLink="download-app" (click)="closeDrawer()"><mat-icon>smartphone</mat-icon>Download App</a>
				<a mat-list-item *ngIf="isPyrocast" (click)="closeDrawer()" href="https://www.pyrocast.com/faqs" target="_blank"><mat-icon>help_outline</mat-icon>FAQs</a>
        <a mat-list-item *ngIf="isPyrocast" (click)="closeDrawer()" href="https://www.pyrocast.com/contact-us" target="_blank"><mat-icon>contact_support</mat-icon>Contact Us</a>
        <a mat-list-item (click)="logout($event)"><mat-icon>logout</mat-icon>Logout</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar [ngStyle]="{'background-color': 'var(--accent-color)', 'color': 'var(--light-color)'}" class="toolbar-sm">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      </mat-toolbar>
      <!-- Add Content Here -->
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <div class="container-full" *ngIf="fullPageTemplate && !isLoading">
    <router-outlet></router-outlet>
  </div>
	<app-alert></app-alert>
