<div class="content">
	<mat-card appearance="outlined" class="example-card">
		<mat-card-content>
			<p>The following lists all shows you have liked specific items. Expand the show to view like by item and show time. You can also download a the data in a CSV file format.</p>
		</mat-card-content>
	</mat-card>
    <div class="my-show-table">  
      <div class="mat-elevation-z8 ex">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows matSort #matSort1="matSort" matSortActive="total_user_likes" matSortDirection="desc">
  
          <!-- Email Column -->
          <ng-container matColumnDef="show_code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Show Code </th>
            <td mat-cell *matCellDef="let element"> {{element.show_code}} </td>
          </ng-container>
  
          <!-- Total Shows Column -->
          <ng-container matColumnDef="custom_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Show Name </th>
            <td mat-cell *matCellDef="let element"> {{element.custom_name}} </td>
          </ng-container>
  
          <!-- Total Brands Column -->
          <ng-container matColumnDef="my_likes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> # My Likes </th>
            <td mat-cell *matCellDef="let element"> {{ element.my_likes > 0 ? element.my_likes : 'n/a'}} </td>
          </ng-container>
  
          <!-- Collaborators Column -->
          <ng-container matColumnDef="total_user_likes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> # Total User Likes </th>
            <td mat-cell *matCellDef="let element"> {{ element.total_user_likes > 0 ? element.total_user_likes : 'n/a'}} </td>
          </ng-container>
  
          <!-- Downloads Column -->
          <ng-container matColumnDef="download_likes">
            <th mat-header-cell *matHeaderCellDef> Download My Likes CSV </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex"><button *ngIf="element.my_likes > 0" mat-icon-button (click)="downloadLikes(element, i)"><mat-icon>{{'arrow_downward'}}</mat-icon></button>
              <span style="padding:12px;" *ngIf="element.my_likes == 0">n/a</span>
            </td>
          </ng-container>
  
          <!-- Downloads Column -->
          <ng-container matColumnDef="download_user_likes">
            <th mat-header-cell *matHeaderCellDef> Download Total User Likes CSV </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex"><button *ngIf="element.total_user_likes > 0" mat-icon-button (click)="downloadUserLikes(element, i)"><mat-icon>{{'arrow_downward'}}</mat-icon></button>
              <span style="padding:12px;" *ngIf="element.total_user_likes == 0">n/a</span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
              <button mat-icon-button (click)="toggleRow(element, i)">
                <mat-icon>
                  {{ expandedElement === element ? 'expand_less' : 'expand_more' }}
                </mat-icon>
              </button>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell class="details-td" *matCellDef="let element" [attr.colspan]="displayedColumns.length + 1">
              <div class="detail-row-table" [hidden]="expandedElement !== element">
                <div style="text-align: center" *ngIf="element.childData.data.length == 0">Loading...</div>
                <table class="child-table" mat-table [hidden]="element.childData.data.length == 0" [dataSource]="element.childData" matSort matSortActive="timestamp" matSortDirection="desc">
                    <ng-container matColumnDef="timestamp">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ element.hasFireworks ? 'Firework Name':'Event Time' }} </th>
                      <td mat-cell *matCellDef="let child"> {{ child.firework_name? child.firework_name : convertTime(child.timestamp)}} </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="show_code">
                      <th mat-header-cell *matHeaderCellDef> Name </th>
                      <td mat-cell *matCellDef="let child"> {{element.show_code}} </td>
                    </ng-container> -->
                    <ng-container matColumnDef="my_likes">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> # My Likes </th>
                      <td mat-cell *matCellDef="let child"> {{child.my_likes}} </td>
                    </ng-container>
                    <ng-container matColumnDef="total_user_likes" *ngIf="element.total_user_likes > 0">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> # Total User Likes </th>
                      <td mat-cell *matCellDef="let child"> {{child.total_user_likes}} </td>
                    </ng-container>
                    <!-- Render the rows -->
                    <tr mat-header-row *matHeaderRowDef="element.total_user_likes > 0 ? displayedColumns2 : displayedColumns3"></tr>
                    <tr mat-row *matRowDef="let row; columns: element.total_user_likes > 0 ? displayedColumns2 : displayedColumns3;" class="mat-row"></tr>
                </table>
              </div>
            </td>
          </ng-container>
  
          <!-- Render the rows -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row"></tr>
  
          <!-- Expanded row -->
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  
          <tr class="mat-row" *matNoDataRow>
            <td colspan="8" class="mat-cell">{{ loading ? 'Loading...' : 'No records found.' }}</td>
          </tr>
  
        </table>
        <mat-paginator [class]="total_reports <= 50 ? 'hidden_dropdown':''" [pageSizeOptions]="[50, 100, 250, 500]" aria-label="Select page of the records."></mat-paginator>
    </div>
  </div>